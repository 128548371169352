import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

const Admin = lazy(() => import('layouts/Admin/ContentWrapper'))
const Front = lazy(() => import('layouts/Front/ContentWrapper'))
const Rider = lazy(() => import('layouts/RiderPortal/ContentWrapper'))
const Driver = lazy(() => import('layouts/DriverPortal/ContentWrapper'))
const Company = lazy(() => import('layouts/CompanyPortal/ContentWrapper'))
const Customer = lazy(() => import('layouts/CustomerPortal/ContentWrapper'))

const App = (props) => {
	return (
		<Suspense fallback={<div />}>
			<Switch>
				<Route path='/admin/' component={Admin} />
				<Route path='/rider/' component={Rider} />
				<Route path='/driver/' component={Driver} />
				<Route path='/company/' component={Company} />
				<Route path='/customer/' component={Customer} />
				<Route path='/' component={Front} />
			</Switch>
		</Suspense>
	)
}

export default App
