const DEV = {
	FIREBASE_API_KEY: 'AIzaSyBqs64LRovfjUe0FvJsZGyL87P49qzKzYA',
	FIREBASE_AUTH_DOMAIN: 'tapit-dev-363e2.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://tapit-dev-363e2.firebaseio.com',
	FIREBASE_PROJECT_ID: 'tapit-dev-363e2',
	FIREBASE_STORAGE_BUCKET: 'tapit-dev-363e2.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '213273567280',

	GOOGLE_MAP_API: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
}

const PROD = {
	FIREBASE_API_KEY: 'AIzaSyB0uNUag0jYTrP96prCzl4aTst3sqmuzyk',
	FIREBASE_AUTH_DOMAIN: 'tapit-prod-b598c.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://tapit-prod-b598c.firebaseio.com',
	FIREBASE_PROJECT_ID: 'tapit-prod-b598c',
	FIREBASE_STORAGE_BUCKET: 'tapit-prod-b598c.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '741185030549',

	GOOGLE_MAP_API: 'AIzaSyC10oErT_m8i0WLcDFlxPmiEwFslLSoU-4',
}

function determine_dev() {
	const stagingURLArray = [
		'', //'https://d1gma93xo1rsqh.cloudfront.net',
		'', //'http://staging.trackittechnology.com.s3-website-us-east-1.amazonaws.com',
		'', //'https://staging.trackittechnology.com',
	]
	return stagingURLArray.includes(window.location.origin) || process.env.NODE_ENV === 'development'
}

export default {
	// dev: false,
	dev: determine_dev(),
	// apiDebugging: true,
	apiDebugging: determine_dev(),

	VERSION: '1.1.2',

	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	// ...DEV,
	// ...PROD,
	...(determine_dev() ? DEV : PROD),
}
