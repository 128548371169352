import { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import { docToDataObject } from 'helpers'

export const useRideByID = (id) => {
	const [singleRide, setSingleRide] = useState()

	useEffect(() => {
		const singleRideWatcher = firebase
			.firestore()
			.doc(`Rides/${id}`)
			.onSnapshot(
				(snapshot) => {
					setSingleRide(docToDataObject(snapshot))
				},
				(e) => {
					console.error(e)
				}
			)
		return () => {
			singleRideWatcher()
		}
	}, [id])

	return singleRide
}
