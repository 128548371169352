import React, { createContext, useState, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import ifvisible from 'ifvisible'
import { TEPopupContext } from 'react-tec'

import { collectionToDataObject, docToDataObject } from 'helpers'
import settings from 'configs/settings'

export const LoadingContext = createContext()

export const LoadingProvider = (props) => {
	const data = useAppLoading()
	return <LoadingContext.Provider value={data}>{props.children}</LoadingContext.Provider>
}

//
// 1) Initialize Firebase
// 2) Start Watching Authentication
// 3) Start Loading App Version
//

const useAppLoading = () => {
	const { dispatch } = useContext(TEPopupContext)
	const [loadingComplete, setLoadingComplete] = useState(false)
	const [user, setUser] = useState()
	const [appVersions, setAppVersions] = useState()
	const [userActive, setUserActive] = useState(true)

	useEffect(() => {
		const {
			FIREBASE_API_KEY,
			FIREBASE_AUTH_DOMAIN,
			FIREBASE_DATABASE_URL,
			FIREBASE_PROJECT_ID,
			FIREBASE_STORAGE_BUCKET,
			FIREBASE_MESSAGING_SENDER_ID,
		} = settings

		firebase.initializeApp({
			apiKey: FIREBASE_API_KEY,
			authDomain: FIREBASE_AUTH_DOMAIN,
			databaseURL: FIREBASE_DATABASE_URL,
			projectId: FIREBASE_PROJECT_ID,
			storageBucket: FIREBASE_STORAGE_BUCKET,
			messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
		})

		//Authentication Watcher
		let userWatcher
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					const { uid } = user
					userWatcher = firebase
						.firestore()
						.doc(`Users/${uid}`)
						.onSnapshot(
							(snapshot) => {
								const user = docToDataObject(snapshot)
								if (!user) {
									firebase.auth().signOut()
									dispatch({
										type: 'show_alert',
										payload: {
											type: 'show_alert',
											payload: {
												alertTitle: 'Incorrect User Permissions',
												alertMessage:
													'At this time we do not support this type of user on the web portal.',
											},
										},
									})
									setUser()
									setLoadingComplete(true)
									return
								}
								if (!user.active) {
									firebase.auth().signOut()
									dispatch({
										type: 'show_alert',
										payload: {
											alertTitle: 'Account Deactivated',
											alertMessage:
												'This account is no longer active. If you believe this account was deactivated in error please contact support@trackit.com',
										},
									})
									setUser()
									setLoadingComplete(true)
									return
								}
								setUser(user)
								setLoadingComplete(true)
							},
							(e) => {
								console.error(e)
							}
						)

					// const data = { uid, last_sign_in: new Date().getTime() }
					// apiRequest('POST', 'update_user', data)
				} else {
					if (userWatcher) {
						userWatcher()
					}
					setUser()
					setLoadingComplete(false)
				}
			},
			(e) => console.error(e),
			() => console.log('onAuthStateChanged Completed')
		)

		//Start Loading App Version
		const appVersionWatcher = firebase
			.firestore()
			.collection('Versions')
			.onSnapshot(
				(snapshot) => {
					setAppVersions(collectionToDataObject(snapshot))
				},
				(e) => {
					console.error(e)
				}
			)

		//Monitor User Activity
		//TODO: Replace with another lib as the timeout doesn't seem to be working
		ifvisible.setIdleDuration(600) //10 Minutes Idle Time
		ifvisible.wakeup(() => {
			setUserActive(true)
		})
		ifvisible.idle(() => {
			setUserActive(false)
		})

		return () => {
			appVersionWatcher()
			if (userWatcher) {
				userWatcher()
			}
			setUser()
			setLoadingComplete(false)
			setAppVersions()
		}
	}, [dispatch])

	return { loadingComplete, user, appVersions, userActive, dispatch }
}
