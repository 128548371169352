import firebase from 'firebase/app'
import 'firebase/firestore'

export const fbAdd = async (collection, fbdata) => {
	fbdata.dateCreated = Date.now()
	const ref = await firebase
		.firestore()
		.collection(collection)
		.add(fbdata)

	return ref
}
export const fbUpdate = async (collection, uid, fbdata) => {
	const ref = await firebase
		.firestore()
		.collection(collection)
		.doc(uid)
		.update(fbdata)

	return ref
}
export const fbSet = async (collection, uid, fbdata) => {
	const ref = await firebase
		.firestore()
		.collection(collection)
		.doc(uid)
		.set(fbdata)

	return ref
}
// const programRequest = firebase
// .firestore()
// .collection(collection)
// .where('active', '==', true)
// .where('client_access_code', '==', code)
// .limit(1)
// .get()
