export const globalStyles = `
	@import url('https://fonts.googleapis.com/css?family=Cabin:400,700,700i|PT+Mono');
	html,
	body {
		margin: 0px;
		padding: 0px;
		font-family: 'Cabin', sans-serif;
		color: #1C1C1C;
	}
	.TESubNavbarLink  {
		font-family: 'PT Mono', monospace;
	}
	.TEpanelTitleTitle {
		font-style: italic;
	}
`
export const theme = {
	primary: '#1C1C1C',
	white: '#FFFFFF',
	black: '#1e1e1e',
	lightGrey: '#efefef',
	red: '#ff0000',
	green: '#00b400',
}
